import { Component } from "react";
import image1 from '../img/Portada.jpg';
import Fade from 'react-reveal/Fade';

class MkAbout extends Component {

    render() {
        return(
            <Fade big>
                <div className="about">                
                    <img src={image1} style={{height:'auto', maxWidth:'90%'}} alt="About MK" />
                    <h2>{this.props.langData.get('hello')}</h2>
                    <div style={{maxWidth:'400px', marginLeft:'auto', marginRight:'auto'}}>
                        <p>{this.props.langData.get('immk')}</p>
                        <p>{this.props.langData.get('amkp1')}</p>
                        <p>{this.props.langData.get('amkp2')}</p>
                        <p>{this.props.langData.get('amkp3')}</p>
                    </div>
                </div>
            </Fade>
        )
    }
}

export default MkAbout;