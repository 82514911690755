import { Component } from "react";
import { Link } from "react-router-dom";

class LeftPane extends Component {

    constructor() {
        super();
        this.doItemClick = this.doItemClick.bind(this)        
    }

    componentDidMount() {
        // Child passes its method to the parent
        this.props.openPane(this.doOpenPane.bind(this))  
        this.props.closePane(this.doclosePane.bind(this))   
     }

      doOpenPane(ev) {
        document.getElementById('Lsidenav').style.width = '270px';
      }

      doclosePane() {
        document.getElementById('Lsidenav').style.width = '0px';
      }

      doItemClick(ev) {
        if(ev.target.id === 'lp_close' || ev.target.id === 'courses') ev.preventDefault();
        document.getElementById('Lsidenav').style.width = '0px';
      }

    render() {  
      return(
        <div id="Lsidenav" className="sidenav">
          <a href="close" id="lp_close" className="closebtn" onClick={this.doItemClick}>&times;</a>
          <ul>
            <li><Link to="/" onClick={this.doItemClick}>{this.props.langData.get('home')}</Link><hr/></li>
            <li><Link to="srv" onClick={this.doItemClick}>{this.props.langData.get('srv')}</Link><hr/></li>
            <li><a href="courses" id="courses" onClick={this.doItemClick}>Courses</a></li>
            <li>
              <ul>
                <li><Link to="smc" onClick={this.doItemClick}>{this.props.langData.get('smc')}</Link></li>
              </ul><hr/>
            </li>
            <li><Link to="shop" onClick={this.doItemClick}>{this.props.langData.get('shop')}</Link><hr/></li>
            <li><Link to="portf" onClick={this.doItemClick}>{this.props.langData.get('portf')}</Link><hr/></li>
            <li><Link to="contact" onClick={this.doItemClick}>{this.props.langData.get('contact')}</Link></li>
          </ul>
			  </div>
      )
    }
}

export default LeftPane;