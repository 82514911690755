import { Component } from "react";

class RightPane extends Component {

    constructor() {
        super();
        this.doItemClick = this.doItemClick.bind(this)
    }

    componentDidMount() {
        // Child passes its method to the parent
        this.props.openPane(this.doOpenPane.bind(this))    
        this.props.closePane(this.doClosePane.bind(this)) 
     }

     doOpenPane(ev) {
        //$("#Rsidenav").css({'width':'250px'}); //jQuery. despliega el panel izquierdo
        document.getElementById('Rsidenav').style.width = '150px';
      }

      doClosePane() {
        document.getElementById('Rsidenav').style.width = '0px';
      }

      doItemClick(ev) {
        ev.preventDefault();
        document.getElementById('Rsidenav').style.width = '0px';
        if(!ev.target.lang) {
          return;
        }
        this.props.onItemClick(ev.target.lang)
      }

      render() {
        return(
            <div id="Rsidenav" className="rightnav">
              <a href="close" id="closerpane" className="closebtn" onClick={this.doItemClick}>&times;</a>
              <a href="en" lang="en" onClick={this.doItemClick}>{this.props.langData.get('en')}</a><hr/>			
              <a href="es" lang="es" onClick={this.doItemClick}>{this.props.langData.get('es')}</a>
            </div>
        )
      }
}

export default RightPane;