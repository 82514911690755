import { Component } from "react"
import { Route, Routes } from "react-router-dom"
import MkAbout from "./MkAbout"
import MkSrv from "./MkSrv"
import MkSmc from "./MkSmc"
import MkProducts from "./MkProducts"
import MkPortf from "./MkPortf"
import MkContact from "./MkContact"
import MkPLayer from "./MkPLayer"
import MkOnlineC from "./MkOnlineC"


class MkRouting extends Component {

    render() {
        return(            
            <Routes>                        
                <Route path="/" element={<MkAbout langData={this.props.langData}/>} />
                <Route path="srv" element={<MkSrv langData={this.props.langData}/>} />
                <Route path="smc" element={<MkSmc langData={this.props.langData}/>} />
                <Route path="shop" element={<MkProducts langData={this.props.langData}/>} />
                <Route path="portf" element={<MkPortf langData={this.props.langData}/>} />
                <Route path="contact" element={<MkContact langData={this.props.langData}/>} />   
                <Route path="onlinec" element={<MkOnlineC langData={this.props.langData}/>} />  
                <Route path="video" element={<MkPLayer langData={this.props.langData}/>} />            
            </Routes> 
        )
    }
}

export default MkRouting