import { Component } from "react";
import cfg from '../data/pdata.json';
import img_instag from '../img/instagram.png';
import img_tiktok from '../img/tiktok.png';
import Fade from 'react-reveal/Fade';

class MkContact extends Component {

    constructor() {
        super()
        this.onItemClick = this.onItemClick.bind(this)
    }

    render() {
        return(
            <Fade big>
                <div>
                    <h1>{this.props.langData.get('contact')}</h1>
                    <div className="contact">
                        <p><b>{this.props.langData.get('call')}</b></p>
                        <p><a href="Call" id="phone" onClick={this.onItemClick}>{cfg.phone}</a></p>
                        <p><b>{this.props.langData.get('email')}</b></p>
                        <p><a href="email" id="mail" onClick={this.onItemClick}>{cfg.email}</a></p>
                        <p><b>{this.props.langData.get('follow')}</b></p>
                        <div>
                            <a href="Instagram" onClick={this.onItemClick}><img id="instg" src={img_instag} alt="Instagram"></img></a>
                            <a href="TikTok" onClick={this.onItemClick}><img id="tiktok" src={img_tiktok} alt="TikTok"></img></a>
                        </div>
                    </div>
                </div>
            </Fade>
        )
    }

    onItemClick(ev) {
        ev.preventDefault()
        switch (ev.target.id) {
            case 'phone':                
                window.open("tel:" + cfg.call, "_self");                
                break; 
            case 'mail':
                window.open("mailto:" + cfg.email , "_self");
                break;
            case 'instg':
                window.open(cfg.instg, "_blank")
                break;
            case 'tiktok':
                window.open(cfg.tiktok, "_blank")
                break;       
            default:
                break;
        }
    }
}

export default MkContact;