import { Component } from "react";
import Fade from 'react-reveal/Fade';

const images = importAll(require.context('../img/services/', false, /\.(png|jpe?g|svg)$/));

function importAll(r) {
    return r.keys().map(r);
  }

class MkSrv extends Component {

    render() {
        return(  
            <Fade big>         
                <div className="srv">
                    <h1>{this.props.langData.get('srv')}</h1>
                    <img src={images[4]} alt="Social makeup"/>
                    <h2>{this.props.langData.get('srv_st01')}</h2>
                    <p>{this.props.langData.get('srv_b01')}</p>
                    <img src={images[0]} alt="15Añeras"/>
                    <h2>{this.props.langData.get('srv_st02')}</h2>
                    <p>{this.props.langData.get('srv_b02')}</p>

                    <img src={images[1]} alt="Bridal"/>
                    <h2>{this.props.langData.get('srv_st03')}</h2>
                    <p>{this.props.langData.get('srv_b03')}</p>

                    <img src={images[5]} alt="Videoclips"/>
                    <h2>{this.props.langData.get('srv_st04')}</h2>
                    <p>{this.props.langData.get('srv_b04')}</p>

                    <img src={images[3]} alt="Runways"/>
                    <h2>{this.props.langData.get('srv_st05')}</h2>
                    <p>{this.props.langData.get('srv_b05')}</p>

                    <img src={images[2]} alt="Editorial"/>
                    <h2>{this.props.langData.get('srv_st06')}</h2>
                    <p>{this.props.langData.get('srv_b06')}</p>
                </div>
            </Fade> 
        )
    }
}

export default MkSrv;