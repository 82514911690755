import React, {Component} from "react"
import Navigation from './components/Navigation';
import LeftPane from './components/LeftPane';
import RightPane from './components/RightPane';
import lang_en from './data/en.json';
import lang_es from './data/es.json';
import MkRouting from "./components/MkRouting";

class MainComp extends Component {

    constructor() {
        super();
        this.onPanel = this.onPanel.bind(this);
        this.acceptDoLeftPane = this.acceptDoLeftPane.bind(this)
        this.acceptDoRightPane = this.acceptDoRightPane.bind(this)
        this.acceptDoCloseLPane = this.acceptDoCloseLPane.bind(this)
        this.acceptDoCloseRPane = this.acceptDoCloseRPane.bind(this)
        this.onRightItemClick = this.onRightItemClick.bind(this)
        this.state = {
          lang:''
        }
        this.lang= {
          lang: 'en',
          langMap: new Map()
        }
        this.curCtn = 'home'
        this.doTranslation()
      }

      acceptDoLeftPane = (doOpenLeft) => {
        // Parent stores the method that the child passed
        this.doOpenLeft = doOpenLeft;
      }
    
      acceptDoRightPane = (doOpenRight) => {
        // Parent stores the method that the child passed
        this.doOpenRight = doOpenRight;
      }
    
      acceptDoCloseLPane = (doCloseLeft) => {
        // Parent stores the method that the child passed
        this.doCloseLeft = doCloseLeft;
      }
    
      acceptDoCloseRPane = (doClosenRight) => {
        // Parent stores the method that the child passed
        this.doClosenRight = doClosenRight;
      }
    
      onPanel(ev) {
        if(ev === 'L') {
          this.doOpenLeft(ev); 
          this.doClosenRight();          
        } else {
          this.doOpenRight(ev);
          this.doCloseLeft();
        }    
      } 
  
      onRightItemClick(ev) {          
        if(ev !== this.lang.lang) {
          this.lang.lang = ev  
          this.doTranslation(ev);
          this.setState({lang: ev})
        }        
      }

    render() {
        return (
            <>
              <div>
                <Navigation onOpenPane={this.onPanel}/>
                <LeftPane langData={this.lang.langMap} openPane={this.acceptDoLeftPane} closePane={this.acceptDoCloseLPane}/>
                <RightPane langData={this.lang.langMap} openPane={this.acceptDoRightPane} closePane={this.acceptDoCloseRPane} onItemClick={this.onRightItemClick}/>
              </div>
              <div className="content">                
                <MkRouting langData={this.lang.langMap}/>                
              </div>
            </>
        )
    }

    doTranslation() {
      this.lang.langMap.clear()
      let langData = this.lang.lang === 'es'? lang_es : lang_en;
      langData.forEach(item => {
        this.lang.langMap.set(item.key, item.value);
      });
  }

}

export default MainComp;