import MainComp from './MainComp';

function App() {

  return(
    <div className="App">
      <MainComp />
    </div>
  );
}

export default App;
