import { Component } from "react";
import Fade from 'react-reveal/Fade';
import cfg from '../data/pdata.json';
import lflogo from '../img/limelife.jpg';
import image1 from '../img/products.jpg';

class MkProducts extends Component {

    constructor() {
        super()
        this.onImgClick = this.onImgClick.bind(this)
    }

      render() {
        return(
            <Fade big>
                <div className="shop">  
                    <img src={lflogo} style={{height:'auto', maxWidth:'95%'}} alt="Limelife"/>                 
                    <div>            
                        <a href="Shop">
                            <img src={image1} style={{height:'auto', maxWidth:'81%'}} alt="Products" onClick={this.onImgClick}/>
                        </a>
                        <h1>{this.props.langData.get('prodtit')}</h1>
                    </div>              
                </div>
            </Fade>
        )
    }

    onImgClick(ev) {
        ev.preventDefault()
        window.open(cfg.prods, "_blank");
    }
}

export default MkProducts;