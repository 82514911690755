import { Component } from "react";
import Fade from 'react-reveal/Fade';
import cfg from '../data/pdata.json';
import image1 from '../img/Curso.jpg';

class MkSmc extends Component {

    render() {
        return(
            <Fade big>
                <div>
                    <h1>{this.props.langData.get('smctit')}</h1>
                    <img src={image1} style={{height:'auto', maxWidth:'90%'}} alt="smc"/>
                    <p className="mk_art_mnu_font" style={{maxWidth:'400px', marginLeft:'auto', marginRight:'auto'}}>{this.props.langData.get('smcpq')}</p>
                    <p className="mk_art_mnu_font" style={{maxWidth:'400px', marginLeft:'auto', marginRight:'auto'}}>{this.props.langData.get('smcp')}</p>
                    <button onClick={this.onBtnClick}>{this.props.langData.get('smcbtn')}</button>
                </div> 
            </Fade>
        )
    }

    onBtnClick(ev) {
        ev.preventDefault();
        window.open(cfg.wsp.smc, "_blank");
    }

}

export default MkSmc;