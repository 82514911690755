import { Link } from "react-router-dom";
import videos from '../data/videos.json';
import cfg from '../data/pdata.json';
import { BraintreePayPalButtons, PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import MkChkLst from "./MkChkLst";
import { useState } from "react";

function MkOnlineC() {

    const [selected, setSelected] = useState([]);
    const PrecioUsd = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      })

    let braintree = false;

    const initialOptions = {
        clientId: "test",
        //dataClientToken:"<the data-client-token value generated by your server-side code>",
        currency: "USD",
        intent: "capture",
    };

    function getCode(ev) {
        ev.preventDefault();
        let vname = ev.target.name.replace(' ', '%20');
        console.log(vname, cfg);
        //window.open(cfg.wsp.vcode + vname, "_blank");
    }

    function onSetSelectedItems(items) {
        setSelected(items);
    }

    function resumeSelected() {
        let price = 0;
        selected.map((item) => {
            let id = Number(item);
            price += videos[id].prix;
            return null;
        });
        let content = `Total payment: ${PrecioUsd.format(price)}`;
        return(selected.length > 0? 
                <div><h2>{content}</h2></div> 
            : null);
    }

    function showOlView() {
        return(<ul>
            {videos.map(
                (video, index)=> {
                    return(<li key={index}><span>{video.name}: </span>
                        <a href="code" onClick={getCode} name={video.name}>Get code</a>{' | '}
                        <Link to="/video" state={{id:index}}>View</Link>
                    </li>)
                }
            )}
            </ul>
        )
    }

    return(
        <div className="online-lst">
            <MkChkLst items={videos} title="Buy online courses :" onSelected={onSetSelectedItems} />
            {resumeSelected()}
            <div className="paypal">
                <div>
                    <PayPalScriptProvider options={initialOptions}>
                        {selected.length >0 ? braintree? PayPalBrainTree(): PayPalBtns() : null}
                    </PayPalScriptProvider>
                </div>
            </div>
            {/*showOlView()*/}
        </div>
    )
}


function PayPalBtns() {

    function createOrder() {
        return fetch("/my-server/create-paypal-order", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            // use the "body" param to optionally pass additional order information
            // like product ids and quantities
            body: JSON.stringify({
                cart: [
                    {
                        id: "YOUR_PRODUCT_ID",
                        quantity: "YOUR_PRODUCT_QUANTITY",
                    },
                ],
            }),
        })
            .then((response) => response.json())
            .then((order) => order.id);
    }

    function onApprove(data) {
        return fetch("/my-server/capture-paypal-order", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            orderID: data.orderID
          })
        })
        .then((response) => response.json())
        .then((orderData) => {
              const name = orderData.payer.name.given_name;
              console.log(`Transaction completed by ${name}`);
        });
    }

    return(
        <PayPalButtons 
            style={{ layout: "horizontal" }}
            onCancel={() => {console.log('Operation cancelled by user')}}
            onApprove={onApprove}
            onError={() => {console.log('Payment error')}}
        />
    )
    //createOrder={createOrder}
}

function PayPalBrainTree() {

    return(
        <BraintreePayPalButtons
            createOrder={(data, actions) => {
                return actions.braintree.createPayment({
                    flow: "checkout",
                    amount: "10.0",
                    currency: "USD",
                    intent: "capture",
                });
            }}
            onApprove={(data, actions) => {
                return actions.braintree
                    .tokenizePayment(data)
                    .then((payload) => {
                        // call server-side endpoint to finish the sale
                    });
            }}
        />
    )
}

export default MkOnlineC;