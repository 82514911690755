import Fade from 'react-reveal/Fade';
import ReactPlayer from "react-player";
import videos from '../data/videos.json';
import { useLocation } from "react-router-dom";
import { useState } from 'react';

function MkPLayer(props) {
    let location = useLocation();
    let [isValidKey, setValidKey]=useState(false);
    let curId = location.state.id;

    function showVideo() {
        return(
            <div className="player">
                <ReactPlayer 
                    className='react-player'
                    url={videos[curId].url}
                    playing={true}
                    controls={true}
                    width={'95vw'}
                    height={'59vh'}
                />
                <h2>{videos[curId].name}</h2>
            </div>
        )
    }

    function onBtnClick(ev) {
        ev.preventDefault();
        let code = document.getElementById('vcode').value;
        if(code === videos[curId].code) setValidKey(true);
        else setValidKey(false);
    }

    function showKeyInput() {
        return(
            <div>
                <p>Type the key to access the course:</p>
                <fieldset>
                    <input type='text' id='vcode' placeholder='Put the code here'/>
                    <span>&nbsp;</span>
                    <button onClick={onBtnClick}>Accept</button>
                </fieldset>
            </div>
        )
    }
    
    return(
        <Fade big>
            {isValidKey? showVideo() : showKeyInput()}
        </Fade>
    )
    

}

export default MkPLayer;