import { useState } from "react";

function MkChkLst(props) {

    let checkList = props.items;
    const [checked, setChecked] = useState([]);
    // Return classes based on whether item is checked
    const isChecked = (item) => checked.includes(item) ? "checked-item" : "not-checked-item";

    // Add/Remove checked item from list
    const handleCheck = (event) => {
        let updatedList = [...checked];
        if (event.target.checked) updatedList = [...checked, event.target.value];
        else updatedList.splice(checked.indexOf(event.target.value), 1);
        setChecked(updatedList);
        props.onSelected(updatedList);
   };

    return (
        <div className="checkList">
            <div className="title"><h2>{props.title}</h2></div>
            <div className="list-container">
            {checkList.map((item, index) => (
                <div key={index}>
                    <input value={index.toString()} type="checkbox" onChange={handleCheck} />
                    <span className={isChecked(index.toString())}>{item.name}</span>
                </div>
            ))}
            </div>
        </div>
    );

}

export default MkChkLst;