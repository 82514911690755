import React, { Component } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Fade from 'react-reveal/Fade';

const images = importAll(require.context('../img/portfolio/', false, /\.(png|jpe?g|svg)$/));

const imageData = [
  {    
    alt: "portl1",//Graphic
    url: images[0]
  },
  {    
    alt: "portl2",//Editorial
    url: images[1]
  },
  {    
    alt: "portl3",//Creative
    url: images[2]
  },
  {    
    alt: "portl4",//Ethnic
    url: images[3]
  },
  {    
    alt: "portl5",//Radiant
    url: images[4]
  },
  {    
    alt: "portl6",//Bold
    url: images[5]
  },
  {    
    alt: "portl7",//Fresh
    url: images[6]
  },  
  {    
    alt: "portl8",//Conceptual
    url: images[7]
  },  
  {    
    alt: "portl9",//Soft Glam
    url: images[8]
  }
];

function importAll(r) {
  return r.keys().map(r);
}


class MkPortf extends Component {

    constructor() {
        super()
        this.state ={
            currentIndex:0
        }
        this.handleChange = this.handleChange.bind(this)
        this.onClickItem = this.onClickItem.bind(this)
    }

    handleChange(index) {
        this.setState({currentIndex:index})
    }

    onClickItem(ev) {
      //console.log('Clicked on image: ' + ev)
    }

    render() {
        const renderSlides = imageData.map((image) => (
            <div key={image.alt}>
              <img src={image.url} alt={image.alt} />
              <p className="legend">{this.props.langData.get(image.alt)}</p>
            </div>
          ));
        return (
          <Fade big>
            <div>
                <h1>{this.props.langData.get('portf')}</h1>
                <div className="mk-portfolio">
                    <Carousel
                    showArrows={true}
                    autoPlay={true}
                    infiniteLoop={true}
                    selectedItem={imageData[this.state.currentIndex]}
                    onChange={this.handleChange}
                    onClickItem={this.onClickItem}
                    className="carousel-container">{renderSlides}</Carousel>
                </div>
            </div> 
          </Fade>
        )
    }
}

export default MkPortf;