import React, {Component} from "react";

import lang_ico from '../img/lang.png';

class Navigation extends Component {

    constructor() {
        super();
        this.onPaneClick = this.onPaneClick.bind(this);
    }

    onPaneClick(ev) {
        ev.preventDefault();
        if(ev.target.id==='left_pane') this.props.onOpenPane('L');
        else this.props.onOpenPane('R');
    }

    render() {

        return (
            <nav className='navbar'>
                <span id="left_pane" className='navbtn-l' onClick={this.onPaneClick}>&#9776;</span>       
                <span id="right_pane" className='navbtn-r' onClick={this.onPaneClick}><img src={lang_ico} alt='Lang' width="27" height="27"/></span>                
            </nav>            
        )
    }

}

export default Navigation;